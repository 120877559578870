import React from 'react'
import SbEditable from 'storyblok-react'
import { SystemSkeleton } from '@system'

const Skeleton = (props) => {
  return (
    <SbEditable content={props.blok}>
      <SystemSkeleton {...props.blok}>
        {props.blok && props.blok.text}
      </SystemSkeleton>
    </SbEditable>
  )
}

export default Skeleton
